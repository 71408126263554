import { createSlice } from "@reduxjs/toolkit";
import SearchParams from "../types/searchParams";
import DogSimple from "../types/dogSimple";
import UserSimple from "../types/userSimple";
import KeyValue from "../types/keyValue";

interface FilterState {
  status: "idle" | "loading" | "failed";
  applicationFilter: SearchParams;
  fosterApplications: boolean;
  applicationDog: DogSimple[];
  applicationPastDog: DogSimple[];
  applicationFoster: UserSimple[];
  applicationStatus: KeyValue[];
  applicationStart?: Date | null;
  applicationEnd?: Date | null;
  dogFilter: SearchParams;
  dogIntakeStart?: Date | null;
  dogIntakeEnd?: Date | null;
  dogStatusStart?: Date | null;
  dogStatusEnd?: Date | null;
  dogFoster: UserSimple[];
  dogStatus: KeyValue[];
  dogSubStatus: KeyValue[];
  userFilter: SearchParams;
  showApplicants: boolean;
  eventFilter: SearchParams;
  featuredEvents: boolean;
  eventStart?: Date | null;
  eventEnd?: Date | null;
  vetsFilter: SearchParams;
  sourcesFilter: SearchParams;
  adoptableDogsFilter: SearchParams;
  adoptableFavorites: boolean;
  adoptableSort: boolean;
  resetDogFilterBool: boolean;
  resetSourceFilterBool: boolean;
}

const initialState: FilterState = {
  status: "idle",
  applicationFilter: {
    pageNumber: 1,
    pageSize: 10,
    orderBy: "submitted",
    orderDirection: "Descending",
    query: "",
    filters: ["finished:1"],
  },
  applicationDog: [],
  applicationPastDog: [],
  fosterApplications: false,
  dogFilter: {
    pageNumber: 1,
    pageSize: 10,
    orderBy: "name",
    orderDirection: "Ascending",
    query: "",
  },
  userFilter: {
    pageNumber: 1,
    pageSize: 10,
    orderDirection: "Ascending",
    query: "",
    filters: ["admin:1", "active:1"],
  },
  showApplicants: false,
  eventFilter: {
    query: "",
    pageNumber: 1,
    pageSize: 10,
  },
  featuredEvents: false,
  vetsFilter: {
    query: "",
    pageNumber: 1,
    pageSize: 10,
  },
  sourcesFilter: {
    query: "",
    pageNumber: 1,
    pageSize: 10,
  },
  adoptableDogsFilter: {
    query: "",
    pageNumber: 1,
    pageSize: 12,
    filters: ["status:2", "status:4", "status:10", "sub:11", "sub:12", "sub:13"],
  },
  adoptableFavorites: false,
  adoptableSort: false,
  resetDogFilterBool: true,
  resetSourceFilterBool: true,
  applicationStatus: [],
  applicationFoster: [],
  dogStatus: [],
  dogFoster: [],
  dogSubStatus: [],
};

const filterSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    setApplicationFilter(state, action) {
      state.applicationFilter = action.payload;
    },
    setFosterApplication(state, action) {
      state.fosterApplications = action.payload;
    },
    setApplicationDog(state, action) {
      state.applicationDog = action.payload;
    },
    setApplicationPastDog(state, action) {
      state.applicationPastDog = action.payload;
    },
    setApplicationStart(state, action) {
      state.applicationStart = action.payload;
    },
    setApplicationEnd(state, action) {
      state.applicationEnd = action.payload;
    },
    resetApplicationFilter(state) {
      state.applicationFilter = { ...initialState.applicationFilter };
    },
    resetApplicationStart(state) {
      state.applicationStart = null;
    },
    resetApplicationEnd(state) {
      state.applicationEnd = null;
    },
    setDogFilter(state, action) {
      state.dogFilter = action.payload;
    },
    setDogIntakeStart(state, action) {
      state.dogIntakeStart = action.payload;
    },
    setDogIntakeEnd(state, action) {
      state.dogIntakeEnd = action.payload;
    },
    setDogStatusStart(state, action) {
      state.dogStatusStart = action.payload;
    },
    setDogStatusEnd(state, action) {
      state.dogStatusEnd = action.payload;
    },
    resetDogFilter(state) {
      state.dogFilter = { ...initialState.dogFilter };
    },
    resetDogIntakeStart(state) {
      state.dogIntakeStart = null;
    },
    resetDogIntakeEnd(state) {
      state.dogIntakeEnd = null;
    },
    resetDogStatusStart(state) {
      state.dogStatusStart = null;
    },
    resetDogStatusEnd(state) {
      state.dogStatusEnd = null;
    },
    setUserFilter(state, action) {
      state.userFilter = action.payload;
    },
    setShowApplicants(state, action) {
      state.showApplicants = action.payload;
    },
    resetUserFilter(state) {
      state.userFilter = { ...initialState.userFilter };
    },
    setEventFilter(state, action) {
      state.eventFilter = action.payload;
    },
    setFeaturedEvents(state, action) {
      state.featuredEvents = action.payload;
    },
    setEventStart(state, action) {
      state.eventStart = action.payload;
    },
    setEventEnd(state, action) {
      state.eventEnd = action.payload;
    },
    resetEventFilter(state) {
      state.eventFilter = { ...initialState.eventFilter };
    },
    resetEventStart(state) {
      state.eventStart = null;
    },
    resetEventEnd(state) {
      state.eventEnd = null;
    },
    setVetFilter(state, action) {
      state.vetsFilter = action.payload;
    },
    setSourceFilter(state, action) {
      state.sourcesFilter = action.payload;
    },
    resetSourceFilter(state) {
      state.sourcesFilter = initialState.sourcesFilter;
    },
    setAdoptableFilter(state, action) {
      state.adoptableDogsFilter = action.payload;
    },
    resetAdoptableFilter(state) {
      state.adoptableDogsFilter = { ...initialState.adoptableDogsFilter };
    },
    setAdoptableFavorites(state, action) {
      state.adoptableFavorites = action.payload;
    },
    setAdoptableSort(state, action) {
      state.adoptableSort = action.payload;
    },
    setResetDogFilterBool(state, action) {
      state.resetDogFilterBool = action.payload;
    },
    setResetSourceFilterBool(state, action) {
      state.resetSourceFilterBool = action.payload;
    },
    setApplicationFoster(state, action) {
      state.applicationFoster = action.payload;
    },
    resetApplicationFoster(state) {
      state.applicationFoster = initialState.applicationFoster;
    },
    setApplicationStatus(state, action) {
      state.applicationStatus = action.payload;
    },
    resetApplicationStatus(state) {
      state.applicationStatus = initialState.applicationStatus;
    },
    setDogFoster(state, action) {
      state.dogFoster = action.payload;
    },
    resetDogFoster(state) {
      state.dogFoster = initialState.dogFoster;
    },
    setDogStatus(state, action) {
      state.dogStatus = action.payload;
    },
    resetDogStatus(state) {
      state.dogStatus = initialState.dogStatus;
      state.dogSubStatus = initialState.dogSubStatus;
    },
    setSubStatus(state, action) {
      state.dogSubStatus = action.payload;
    },
    resetSubStatus(state) {
      state.dogSubStatus = initialState.dogSubStatus;
    },
  },
  extraReducers: (builder) => {},
});

export default filterSlice.reducer;

export const {
  setApplicationFilter,
  setFosterApplication,
  setApplicationDog,
  setApplicationPastDog,
  setApplicationStart,
  setApplicationEnd,
  resetApplicationFilter,
  resetApplicationStart,
  resetApplicationEnd,
  setDogFilter,
  setDogIntakeStart,
  setDogIntakeEnd,
  setDogStatusStart,
  setDogStatusEnd,
  resetDogFilter,
  resetDogIntakeStart,
  resetDogIntakeEnd,
  resetDogStatusStart,
  resetDogStatusEnd,
  setUserFilter,
  setShowApplicants,
  resetUserFilter,
  setEventFilter,
  setFeaturedEvents,
  setEventStart,
  setEventEnd,
  resetEventFilter,
  resetEventStart,
  resetEventEnd,
  setVetFilter,
  setSourceFilter,
  setAdoptableFilter,
  resetAdoptableFilter,
  setAdoptableFavorites,
  setAdoptableSort,
  setResetDogFilterBool,
  setResetSourceFilterBool,
  setApplicationFoster,
  resetApplicationFoster,
  setApplicationStatus,
  resetApplicationStatus,
  resetSourceFilter,
  setSubStatus,
  resetSubStatus,
  setDogFoster,
  resetDogFoster,
  setDogStatus,
  resetDogStatus,
} = filterSlice.actions;
