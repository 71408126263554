import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { Field, Form, Formik, FormikProps, getIn } from "formik";
import * as Yup from "yup";
import { AccountCircle, Medication } from "@mui/icons-material";
import { Inputs } from "../../forms";
import { getStatesSearch } from "../../../slices/options";
import { saveReferencesStep } from "../../../slices/applications";
import { useDispatch, useSelector } from "../../../store";
import References from "../../../types/application/references";

interface Props {
  formikRef?: React.RefObject<FormikProps<any>>;
}

const ReferencesStep: React.FC<Props> = (props) => {
  const dispatch = useDispatch();

  const initialState: References = useSelector((store) => ({
    currentPets: store.applications.myApplication?.currentPets,
    hasPets:
      store.applications.myApplication?.numberOfDogs! > 0 ||
      store.applications.myApplication?.numberOfCats! > 0 ||
      store.applications.myApplication?.numberOfOtherPets! > 0 ||
      store.applications.myApplication?.numberOfPastPets! > 0,
    petsSeeVet: store.applications.myApplication?.petsSeeVet || false,
    whyNoVet: store.applications.myApplication?.whyNoVet,
    addSecondVet:
      (!!store.applications.myApplication?.petsSeeVet && !!store.applications.myApplication?.vetReferences?.[1]) ||
      false,
    personalReference1: {
      id: store.applications.myApplication?.personalReferences?.[0]?.id,
      name: store.applications.myApplication?.personalReferences?.[0]?.name,
      phone: store.applications.myApplication?.personalReferences?.[0]?.phone,
      relationship: store.applications.myApplication?.personalReferences?.[0]?.relationship,
    },
    personalReference2: {
      id: store.applications.myApplication?.personalReferences?.[1]?.id,
      name: store.applications.myApplication?.personalReferences?.[1]?.name,
      phone: store.applications.myApplication?.personalReferences?.[1]?.phone,
      relationship: store.applications.myApplication?.personalReferences?.[1]?.relationship,
    },
    vetReference1: !!store.applications.myApplication?.vetReferences?.[0]
      ? {
          id: store.applications.myApplication?.vetReferences?.[0]?.id,
          name: store.applications.myApplication?.vetReferences?.[0]?.name,
          petsOnFile: store.applications.myApplication?.vetReferences?.[0]?.petsOnFile || "",
          phone: store.applications.myApplication?.vetReferences?.[0]?.phone,
          city: store.applications.myApplication?.vetReferences?.[0]?.city,
          state: store.applications.myApplication?.vetReferences?.[0]?.state,
        }
      : {},
    vetReference2: !!store.applications.myApplication?.vetReferences?.[1]
      ? {
          id: store.applications.myApplication?.vetReferences?.[1]?.id,
          name: store.applications.myApplication?.vetReferences?.[1]?.name,
          petsOnFile: store.applications.myApplication?.vetReferences?.[1]?.petsOnFile || "",
          phone: store.applications.myApplication?.vetReferences?.[1]?.phone,
          city: store.applications.myApplication?.vetReferences?.[1]?.city,
          state: store.applications.myApplication?.vetReferences?.[1]?.state,
        }
      : {},
  }));

  const handleSubmit = (values: any) => {
    dispatch(saveReferencesStep(values));
  };

  const validationSchema = Yup.object().shape({
    personalReference1: Yup.object()
      .shape({
        name: Yup.string().required("Required"),
        relationship: Yup.string().required("Required"),
        phone: Yup.string().required("Required").min(14, "Must be a valid phone number"),
      })
      .required("Required"),
    personalReference2: Yup.object()
      .shape({
        name: Yup.string().required("Required"),
        relationship: Yup.string().required("Required"),
        phone: Yup.string().required("Required").min(14, "Must be a valid phone number"),
      })
      .required("Required"),
    petsSeeVet: Yup.boolean().nullable(),
    whyNoVet: Yup.string()
      .nullable()
      .when("petsSeeVet", {
        is: (value: boolean) => !value && !!initialState.hasPets,
        then: (schema) => schema.required("Required"),
        otherwise: (x) => x,
      }),
    vetReference1: Yup.object().when("petsSeeVet", {
      is: (value: boolean) => !!value && !!initialState.hasPets,
      then: (schema) =>
        schema
          .shape({
            name: Yup.string().required("Required"),
            petsOnFile: Yup.string().nullable(),
            phone: Yup.string().required("Required").min(14, "Must be a valid phone number"),
            city: Yup.string().required("Required"),
            state: Yup.object().typeError("Delete typed input and select an option").required("Required"),
          })
          .nullable()
          .required("Required"),
      otherwise: (x) => x,
    }),
    addSecondVet: Yup.boolean().nullable(),
    vetReference2: Yup.object().when("addSecondVet", {
      is: (value: boolean) => !!value && !!initialState.hasPets,
      then: (schema) =>
        schema
          .shape({
            name: Yup.string().required("Required"),
            petsOnFile: Yup.string().nullable(),
            phone: Yup.string().required("Required").min(14, "Must be a valid phone number"),
            city: Yup.string().required("Required"),
            state: Yup.object().typeError("Delete typed input and select an option").required("Required"),
          })
          .nullable()
          .required("Required"),
      otherwise: (x) => x,
    }),
  });

  return (
    initialState && (
      <Formik
        initialValues={initialState}
        validationSchema={validationSchema}
        innerRef={props.formikRef}
        onSubmit={handleSubmit}
        validateOnMount
      >
        {({ values, setFieldValue, errors }) => (
          <Form noValidate>
            <Box>
              <Typography variant="h3" sx={{ marginBottom: 2 }}>
                Please provide two (2) personal references. These people should NOT be related to you or other members
                of your household. Your best choice is people who can speak to your experience with dogs.
              </Typography>
            </Box>
            <Grid container sx={{ paddingTop: "5px", marginBottom: 10 }}>
              <Grid container sx={{}}>
                <Grid item xs={12}>
                  <Typography
                    variant="h3"
                    sx={{ fontWeight: "bold", marginBottom: "10px", marginLeft: "10px" }}
                  >{`Reference 1`}</Typography>
                </Grid>
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ display: "flex", justifyContent: "center", alignItems: "center", paddingBottom: "25px" }}
                >
                  <AccountCircle fontSize="large" />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Field component={Inputs.Text} name={`personalReference1.name`} label={`Name`} required />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Field
                    component={Inputs.Text}
                    name={`personalReference1.relationship`}
                    label={`Relationship`}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Field component={Inputs.Phone} name={`personalReference1.phone`} label={`Phone`} required />
                </Grid>
              </Grid>
              <Grid container sx={{}}>
                <Grid item xs={12}>
                  <Typography
                    variant="h3"
                    sx={{ fontWeight: "bold", marginBottom: "10px", marginLeft: "10px" }}
                  >{`Reference 2`}</Typography>
                </Grid>
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{ display: "flex", justifyContent: "center", alignItems: "center", paddingBottom: "25px" }}
                >
                  <AccountCircle fontSize="large" />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Field component={Inputs.Text} name={`personalReference2.name`} label={`Name`} required />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Field
                    component={Inputs.Text}
                    name={`personalReference2.relationship`}
                    label={`Relationship`}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Field component={Inputs.Phone} name={`personalReference2.phone`} label={`Phone`} required />
                </Grid>
              </Grid>
              {!!initialState.hasPets && (
                <Grid item xs={12}>
                  <Field
                    component={Inputs.Switch}
                    name="petsSeeVet"
                    label={`Do/did your pets see a vet?`}
                    onChange={(value: boolean) => {
                      if (value === true) {
                        setFieldValue("vetReference1.name", "");
                        setFieldValue("vetReference1.petsOnFile", "");
                        setFieldValue("vetReference1.phone", "");
                        setFieldValue("vetReference1.city", "");
                        setFieldValue("vetReference1.state", undefined);
                        setFieldValue("addSecondVet", false);
                        setFieldValue("vetReference2.name", "");
                        setFieldValue("vetReference2.petsOnFile", "");
                        setFieldValue("vetReference2.phone", "");
                        setFieldValue("vetReference2.city", "");
                        setFieldValue("vetReference2.state", undefined);
                        setFieldValue("whyNoVet", undefined);
                      } else {
                        setFieldValue("vetReference1", undefined);
                      }
                    }}
                  />
                </Grid>
              )}
              {!!values.petsSeeVet && !!initialState.hasPets ? (
                <>
                  <Grid container sx={{}}>
                    <Grid item xs={12}>
                      <Typography
                        variant="h3"
                        sx={{ fontWeight: "bold", marginBottom: "10px", marginLeft: "10px" }}
                      >{`Veterinarian 1`}</Typography>
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      md={1}
                      sx={{ display: "flex", justifyContent: "center", alignItems: "center", paddingBottom: "25px" }}
                    >
                      <Medication fontSize="large" />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Field
                        component={Inputs.Text}
                        name={`vetReference1.name`}
                        label={`Veterinarian Name`}
                        required={!!values.petsSeeVet && !!initialState.hasPets}
                      />
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <Field
                        component={Inputs.Text}
                        name={`vetReference1.petsOnFile`}
                        label={`Pet Names`}
                        required={!!values.petsSeeVet && !!initialState.hasPets}
                      />
                    </Grid>
                    <Grid item xs={1} md={1} />
                    <Grid item xs={12} md={4}>
                      <Field
                        component={Inputs.Phone}
                        name={`vetReference1.phone`}
                        label={`Phone`}
                        required={!!values.petsSeeVet && !!initialState.hasPets}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Field
                        component={Inputs.Text}
                        name={`vetReference1.city`}
                        label={`City`}
                        required={!!values.petsSeeVet && !!initialState.hasPets}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Field
                        component={Inputs.OptionDropdown}
                        searchFunction={getStatesSearch}
                        name={`vetReference1.state`}
                        label={`State`}
                        required={!!values.petsSeeVet && !!initialState.hasPets}
                        filterForm={{ pageSize: 50 }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={Inputs.Switch}
                      name="addSecondVet"
                      label={`Do you want to add a second vet?`}
                      onChange={(value: boolean) => {
                        if (value === true) {
                          setFieldValue("vetReference2.name", "");
                          setFieldValue("vetReference2.petsOnFile", "");
                          setFieldValue("vetReference2.phone", "");
                          setFieldValue("vetReference2.city", "");
                          setFieldValue("vetReference2.state", undefined);
                          setFieldValue("whyNoVet", undefined);
                        } else {
                          setFieldValue("vetReference2", undefined);
                        }
                      }}
                    />
                  </Grid>
                  {!!values.addSecondVet && (
                    <Grid container sx={{}}>
                      <Grid item xs={12}>
                        <Typography
                          variant="h3"
                          sx={{ fontWeight: "bold", marginBottom: "10px", marginLeft: "10px" }}
                        >{`Veterinarian 2`}</Typography>
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        md={1}
                        sx={{ display: "flex", justifyContent: "center", alignItems: "center", paddingBottom: "25px" }}
                      >
                        <Medication fontSize="large" />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Field
                          component={Inputs.Text}
                          name={`vetReference2.name`}
                          label={`Veterinarian Name`}
                          required={!!initialState.hasPets && !!values.petsSeeVet && !!values.addSecondVet}
                        />
                      </Grid>
                      <Grid item xs={12} md={7}>
                        <Field
                          component={Inputs.Text}
                          name={`vetReference2.petsOnFile`}
                          label={`Pet Names`}
                          required={!!initialState.hasPets && !!values.petsSeeVet && !!values.addSecondVet}
                        />
                      </Grid>
                      <Grid item xs={1} md={1} />
                      <Grid item xs={12} md={4}>
                        <Field
                          component={Inputs.Phone}
                          name={`vetReference2.phone`}
                          label={`Phone`}
                          required={!!initialState.hasPets && !!values.petsSeeVet && !!values.addSecondVet}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Field
                          component={Inputs.Text}
                          name={`vetReference2.city`}
                          label={`City`}
                          required={!!initialState.hasPets && !!values.petsSeeVet && !!values.addSecondVet}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Field
                          component={Inputs.OptionDropdown}
                          searchFunction={getStatesSearch}
                          name={`vetReference2.state`}
                          label={`State`}
                          required={!!initialState.hasPets && !!values.petsSeeVet && !!values.addSecondVet}
                          filterForm={{ pageSize: 50 }}
                        />
                      </Grid>
                    </Grid>
                  )}
                </>
              ) : (
                !values.petsSeeVet &&
                !!initialState.hasPets && (
                  <Grid item xs={12}>
                    <Field
                      component={Inputs.TextArea}
                      name="whyNoVet"
                      label={`Why not?`}
                      required={!values.petsSeeVet && !!initialState.hasPets}
                    />
                  </Grid>
                )
              )}
            </Grid>
          </Form>
        )}
      </Formik>
    )
  );
};

export default ReferencesStep;
