import React from "react";

interface Props {}

const DonationBox: React.FC<Props> = (props) => {
  return (
    <>
      <script
        async
        src="https://secure.qgiv.com/resources/core/js/embed.js"
        data-qgiv-embed={true}
        data-embed-id="78560"
      ></script>
      <iframe
        src="https://secure.qgiv.com/for/homeatlastdogrescue/embed/78560/"
        name="qgiv-embed-container"
        title="qgiv-embed-container"
        width="100%"
        style={{
          maxWidth: "525px",
          width: "100%",
          minWidth: "325px",
          maxHeight: "none!important",
          minHeight: "100%",
          border: "0px",
        }}
      />
    </>
  );
};

export default DonationBox;
