import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "../../store";
import {
  Box,
  Grid,
  DialogActions,
  Typography,
  CircularProgress,
  AutocompleteChangeReason,
  AutocompleteChangeDetails,
} from "@mui/material";
import StyledTable from "../base/StyledTable";
import TableActions from "../base/TableActions";
import Search from "../base/Search";
import { Container } from "@mui/system";
import ActionButton from "../base/ActionButton";
import { getUserSearch } from "../../slices/users";
import { debounce } from "lodash";
import KeyValue from "../../types/keyValue";
import { useNavigate } from "react-router-dom";
import { getStatusOptions, getSubStatus } from "../../slices/options";
import { Field, Form, Formik } from "formik";
import { Inputs } from "../forms";
import {
  clearDogSearch,
  deleteDog,
  getDogById,
  getDogSimpleById,
  getDogsSearch,
  resetDog,
  setDog,
} from "../../slices/dogs";
import DogSimple from "../../types/dogSimple";
import Moment from "react-moment";
import Dialog from "../base/Dialog";
import checkUserRole, { UserRoles } from "../../helpers/checkUserRole";
import {
  resetDogFoster,
  resetDogStatus,
  resetDogFilter,
  resetDogIntakeEnd,
  resetDogIntakeStart,
  resetDogStatusEnd,
  resetDogStatusStart,
  resetSubStatus,
  setDogFoster,
  setDogStatus,
  setDogFilter,
  setDogIntakeEnd,
  setDogIntakeStart,
  setDogStatusEnd,
  setDogStatusStart,
  setResetDogFilterBool,
  setSubStatus,
} from "../../slices/filters";
import { combineDocuments } from "../../slices/file";
import CheckboxButton from "../base/CheckboxButton";
import SearchResults from "../../types/searchResults";

interface Props {}

const AdminDogTable: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loggedInUser } = useSelector((store) => store.auth);
  const { dog, dogSearch, searchStatus, status } = useSelector((store) => store.dogs);
  const { dogFilter, dogIntakeStart, dogIntakeEnd, dogStatusStart, dogStatusEnd, dogFoster, dogStatus, dogSubStatus } =
    useSelector((store) => store.filters);
  const { downloadStatus } = useSelector((store) => store.file);
  const { subStatus } = useSelector((store) => store.options);

  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(0);
  const [selectAll, setSelectAll] = useState(false);
  const [selecting, setSelecting] = useState(false);
  const [subStatusFilters, setSubStatusFilters] = useState<string[]>();

  const initialCheckedState = dogSearch?.results
    ? dogSearch?.results?.reduce((acc, file) => {
        acc[file.id!] = false;
        return acc;
      }, {} as { [key: number]: boolean })
    : ({} as { [key: number]: boolean });

  const [checked, setChecked] = useState(initialCheckedState);

  useEffect(() => {
    dispatch(clearDogSearch());
  }, [dispatch]);

  useEffect(() => {
    // If the dates are cleared (displayed as December 31, 1969), reset them to null.
    let intakeStartDate = dogIntakeStart ?? null;
    let intakeEndDate = dogIntakeEnd ?? null;
    if (dogIntakeStart?.toDateString() === "Wed Dec 31 1969") {
      intakeStartDate = null;
      dispatch(setDogIntakeStart(null));
    }
    if (dogIntakeEnd?.toDateString() === "Wed Dec 31 1969") {
      intakeEndDate = null;
      dispatch(setDogIntakeEnd(null));
    }

    let statusStartDate = dogStatusStart ?? null;
    let statusEndDate = dogStatusEnd ?? null;
    if (dogStatusStart?.toDateString() === "Wed Dec 31 1969") {
      statusStartDate = null;
      dispatch(setDogStatusStart(null));
    }
    if (dogStatusEnd?.toDateString() === "Wed Dec 31 1969") {
      statusEndDate = null;
      dispatch(setDogStatusEnd(null));
    }

    // Validate the dates. (If a date is null, we consider it valid.)
    const isValidDate = (date: Date | null) => date === null || (date instanceof Date && !isNaN(date.valueOf()));

    if (
      isValidDate(intakeStartDate) &&
      isValidDate(intakeEndDate) &&
      isValidDate(statusStartDate) &&
      isValidDate(statusEndDate)
    ) {
      dispatch(
        getDogsSearch({
          search: { ...dogFilter },
          intakeStart: intakeStartDate!,
          intakeEnd: intakeEndDate!,
          statusStart: statusStartDate!,
          statusEnd: statusEndDate!,
        })
      );
    }
  }, [dispatch, dogIntakeStart, dogIntakeEnd, dogStatusStart, dogStatusEnd, dogFilter]);

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, checked: isChecked } = event.currentTarget;

    if (id === "selectAll") {
      setSelectAll(isChecked);
      setSelecting(true);
      dispatch(
        getDogsSearch({
          ...dogFilter,
          search: { ...dogFilter, pageNumber: 1, pageSize: dogSearch?.totalResults },
          intakeStart: dogIntakeStart!,
          intakeEnd: dogIntakeEnd!,
          statusStart: dogStatusStart!,
          statusEnd: dogStatusEnd!,
        })
      ).then((result) => {
        if (result && result.payload) {
          let searches = result.payload as SearchResults;
          setChecked((prevChecked: { [key: number]: boolean }) => {
            const updatedState: { [key: number]: boolean } = {};
            searches?.results?.forEach((dog) => {
              updatedState[dog.id!] = isChecked;
            });
            setSelecting(false);
            return { ...prevChecked, ...updatedState };
          });
        }
      });
    } else {
      setChecked((prevChecked: { [key: number]: boolean }) => ({
        ...prevChecked,
        [parseInt(id)]: isChecked,
      }));
    }
  };

  const downloadAllDocuments = () => {
    dispatch(
      combineDocuments({
        ...dogFilter,
        pageNumber: 1,
        pageSize: dogSearch?.totalResults,
      })
    );
  };

  const printSelectedDocuments = () => {
    let idFilters: string[] = [];

    for (const key in checked) {
      if (checked.hasOwnProperty(key)) {
        const value = checked[key];
        if (!!value) {
          idFilters.push(`dog-id:${key}`);
        }
      }
    }

    dispatch(
      combineDocuments({
        ...dogFilter,
        pageSize: dogSearch?.totalResults,
        filters: idFilters,
      })
    );
  };

  const handleDeleteOpen = (id: number) => {
    setDeleteModal(true);
    dispatch(getDogById(id));
    setDeleteId(id);
  };

  const handleDeleteDog = async (id: number) => {
    await dispatch(deleteDog(id));
  };

  const cancelDelete = () => {
    setDeleteModal(false);
    setDeleteId(0);
  };

  const updateSearch = debounce((query: string) => {
    dispatch(setDogFilter({ ...dogFilter, pageNumber: 1, pageSize: 10, query: query }));
  }, 500);

  const handleFiltering = (e: KeyValue[], filterName: string) => {
    let filters = dogFilter?.filters;
    let otherFilters = filters?.filter((f) => f.split(":")[0] !== filterName);

    if (!!e) {
      let newFilters: string[] = [];

      e.forEach((filter: KeyValue) => {
        let newFilterName = `${filterName}:${filter.id}`;
        newFilters.push(newFilterName);
      });

      let newList = otherFilters ? newFilters.concat(otherFilters) : newFilters;
      dispatch(setDogFilter({ ...dogFilter, pageNumber: 1, pageSize: 10, filters: newList }));

      if (filterName === "status") {
        setSubStatusFilters(newFilters);
        dispatch(getSubStatus({ filters: newList }));
      }
    } else {
      dispatch(setDogFilter({ ...dogFilter, pageNumber: 1, pageSize: 10, filters: otherFilters }));
    }
  };

  const changePage = (pageNumber: number, pageSize: number) => {
    dispatch(setDogFilter({ ...dogFilter, pageNumber: pageNumber, pageSize: pageSize }));
  };

  const initialOrderDirections = {
    id: true,
    name: false,
    aka: true,
    breed: true,
    foster: true,
    status: true,
    intake: true,
    statusChanged: true,
  };

  const [orderDirections, setOrderDirections] = useState(initialOrderDirections);

  const toggleOrder = (field: keyof typeof orderDirections): boolean => {
    const newValue = !orderDirections[field];
    const newDirections = Object.keys(orderDirections).reduce((acc, key) => {
      acc[key as keyof typeof orderDirections] = key === field ? newValue : !newValue;
      return acc;
    }, {} as typeof orderDirections);
    setOrderDirections(newDirections);
    return newValue;
  };

  const fieldMap: { [key: string]: keyof typeof orderDirections } = {
    ID: "id",
    Name: "name",
    AKA: "aka",
    Breed: "breed",
    Foster: "foster",
    Changed: "statusChanged",
    Status: "status",
    Intake: "intake",
  };

  const handleSort = (e: any) => {
    const targetId = e.target.id;
    const field = Object.keys(fieldMap).find((key) => targetId.includes(key));
    if (!field) return;

    const sortField = fieldMap[field];
    const newOrderValue = toggleOrder(sortField);
    const orderString = newOrderValue ? "Descending" : "Ascending";

    dispatch(setDogFilter({ ...dogFilter, orderBy: sortField, orderDirection: orderString }));
  };

  const headers = [
    "Actions",
    `ID ${orderDirections.id ? "↓" : "↑"}`,
    `Dog Name ${orderDirections.name ? "↓" : "↑"}`,
    `AKA ${orderDirections.aka ? "↓" : "↑"}`,
    `Breed ${orderDirections.breed ? "↓" : "↑"}`,
    `Foster ${orderDirections.foster ? "↓" : "↑"}`,
    `Status ${orderDirections.status ? "↓" : "↑"}`,
    `Intake Date ${orderDirections.intake ? "↓" : "↑"}`,
    `Status Changed ${orderDirections.statusChanged ? "↓" : "↑"}`,
  ];

  if (checkUserRole(loggedInUser!, UserRoles.Admin)) {
    headers.unshift("Print");
  }

  const rows =
    dogSearch?.results.map((dog: DogSimple) => {
      const hasDownload = checkUserRole(loggedInUser!, UserRoles.Admin);

      let rowData = {
        actions: (
          <TableActions
            handleEdit={
              checkUserRole(loggedInUser!, UserRoles.Admin) || checkUserRole(loggedInUser!, UserRoles.Inventory)
                ? async () => {
                    dispatch(setResetDogFilterBool(false));
                    await dispatch(getDogById(dog.id)).then(() => {
                      navigate(`/admin/dog/${dog.id}`, { state: { edit: true } });
                    });
                    dispatch(getDogSimpleById(dog.id));
                  }
                : undefined
            }
            handleDelete={checkUserRole(loggedInUser!, UserRoles.Admin) ? handleDeleteOpen : undefined}
            id={dog.id}
            handleView={async () => {
              dispatch(setResetDogFilterBool(false));
              await dispatch(getDogById(dog.id)).then(() => {
                navigate(`/admin/dog/${dog.id}`);
              });
              dispatch(getDogSimpleById(dog.id));
            }}
            handleCopy={
              checkUserRole(loggedInUser!, UserRoles.Admin) || checkUserRole(loggedInUser!, UserRoles.Inventory)
                ? async () => {
                    dispatch(setResetDogFilterBool(false));
                    const dogClone: any = (await dispatch(getDogById(dog.id))).payload;
                    dispatch(
                      setDog({
                        ...dogClone,
                        id: 0,
                        name: "",
                        alsoKnownAs: "",
                        medicalDog: false,
                        ageGroup: { id: 0, value: "" },
                        microchipNumber: "",
                        status: { id: 1, value: "On Hold - Other" },
                        mainPhotoId: null,
                        photos: [],
                        documents: [],
                        notes: [],
                      })
                    );
                    navigate(`/admin/dog/0`);
                  }
                : undefined
            }
          />
        ),
        id: dog.id,
        dogName: dog.name,
        aka: dog.alsoKnownAs,
        breed: dog.primaryBreed?.value,
        foster: dog.foster?.value,
        status: `${dog.status?.value} ${dog.subStatus?.value ? `- ${dog.subStatus.value}` : ""}`,
        intake: dog.intakeDate && <Moment format="MM/DD/YYYY">{dog.intakeDate!}</Moment>,
        statusChanged: dog.statusUpdated && <Moment format="MM/DD/YYYY">{dog.statusUpdated!}</Moment>,
      };

      return !hasDownload
        ? rowData
        : {
            download: (
              <CheckboxButton
                colorVariant="blue"
                checked={checked[dog.id] || false}
                onChange={handleSelectAll}
                id={dog?.id!.toString()}
              />
            ),
            actions: (
              <TableActions
                handleEdit={
                  checkUserRole(loggedInUser!, UserRoles.Admin) || checkUserRole(loggedInUser!, UserRoles.Inventory)
                    ? async () => {
                        dispatch(setResetDogFilterBool(false));
                        await dispatch(getDogById(dog.id)).then(() => {
                          navigate(`/admin/dog/${dog.id}`, { state: { edit: true } });
                        });
                        dispatch(getDogSimpleById(dog.id));
                      }
                    : undefined
                }
                handleDelete={checkUserRole(loggedInUser!, UserRoles.Admin) ? handleDeleteOpen : undefined}
                id={dog.id}
                handleView={async () => {
                  dispatch(setResetDogFilterBool(false));
                  await dispatch(getDogById(dog.id)).then(() => {
                    navigate(`/admin/dog/${dog.id}`);
                  });
                  dispatch(getDogSimpleById(dog.id));
                }}
                handleCopy={
                  checkUserRole(loggedInUser!, UserRoles.Admin) || checkUserRole(loggedInUser!, UserRoles.Inventory)
                    ? async () => {
                        dispatch(setResetDogFilterBool(false));
                        const dogClone: any = (await dispatch(getDogById(dog.id))).payload;
                        dispatch(
                          setDog({
                            ...dogClone,
                            id: 0,
                            name: "",
                            alsoKnownAs: "",
                            medicalDog: false,
                            ageGroup: { id: 0, value: "" },
                            microchipNumber: "",
                            status: { id: 1, value: "On Hold - Other" },
                            mainPhotoId: null,
                            photos: [],
                            documents: [],
                            notes: [],
                          })
                        );
                        navigate(`/admin/dog/0`);
                      }
                    : undefined
                }
              />
            ),
            id: dog.id,
            dogName: dog.name,
            aka: dog.alsoKnownAs,
            breed: dog.primaryBreed?.value,
            foster: dog.foster?.value,
            status: `${dog.status?.value} ${dog.subStatus?.value ? `- ${dog.subStatus.value}` : ""}`,
            intake: dog.intakeDate && <Moment format="MM/DD/YYYY">{dog.intakeDate!}</Moment>,
            statusChanged: dog.statusUpdated && <Moment format="MM/DD/YYYY">{dog.statusUpdated!}</Moment>,
          };
    }) || [];

  let date = new Date();

  const handleStatusFilter = (value: KeyValue[]) => {
    handleFiltering(value, "status");
    dispatch(setDogStatus(value));
  };

  const handleSubSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      getSubStatus({
        filters: subStatusFilters,
        query: e.currentTarget.value,
      })
    );
  };

  const handleSubStatusFilter = (
    event: React.SyntheticEvent,
    value: any | Array<any>,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<any>
  ) => {
    handleFiltering(value, "sub");
    dispatch(setSubStatus(value));
  };

  const handleFosterFilter = (value: KeyValue[]) => {
    handleFiltering(value, "foster");
    dispatch(setDogFoster(value));
  };

  const handleClear = useCallback(async () => {
    dispatch(resetDogFilter());
    dispatch(resetDogIntakeStart());
    dispatch(resetDogIntakeEnd());
    dispatch(resetDogStatusStart());
    dispatch(resetDogStatusEnd());
    dispatch(resetDogFoster());
    dispatch(resetDogStatus());
    dispatch(resetSubStatus());
  }, [dispatch]);

  const handleReset = (resetForm: () => void) => {
    resetForm();
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", marginTop: -1 }}>
      <Container sx={{ marginBottom: 3 }}>
        <Formik
          enableReinitialize
          initialValues={{
            intakeStart: dogIntakeStart,
            intakeEnd: dogIntakeEnd,
            statusStart: dogStatusStart,
            statusEnd: dogStatusEnd,
            status: dogStatus,
            foster: dogFoster,
            dogSubStatus: dogSubStatus,
          }}
          onSubmit={() => {}}
        >
          {({ values, resetForm }) => {
            return (
              <Form noValidate>
                <Box sx={{ alignItems: "center", display: "flex", justifyContent: "center", marginBottom: "5px" }}>
                  <Container>
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        justifyContent: "space-between",
                      }}
                    >
                      {checkUserRole(loggedInUser!, UserRoles.Admin) && (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            justifyContent: "flex-start",
                            padding: 1,
                          }}
                        >
                          <Box>
                            <ActionButton
                              text={"Download All Documents"}
                              onClick={downloadAllDocuments}
                              type="button"
                              color="secondary"
                              disabled={downloadStatus === "loading"}
                            />
                          </Box>
                        </Box>
                      )}
                      <Box
                        gap={1}
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                          justifyContent: "flex-start",
                          padding: 1,
                        }}
                      >
                        <Box>
                          {downloadStatus === "loading" && (
                            <Box display="flex" justifyContent="center" alignItems="center" paddingLeft="10px">
                              <CircularProgress color="primary" size="20px" />
                            </Box>
                          )}
                        </Box>
                        <Box sx={{ display: { sm: "none", xs: "flex" } }}>
                          <ActionButton
                            text={showFilters ? "Hide Filters" : "Show Filters"}
                            onClick={() => setShowFilters(!showFilters)}
                            type="button"
                            color="secondary"
                          />
                        </Box>

                        <Box sx={{ display: { sm: "flex", xs: showFilters ? "flex" : "none" } }}>
                          <ActionButton
                            type="reset"
                            text="Reset Filters"
                            color="success"
                            onClick={async () => {
                              await handleClear();
                              handleReset.bind(resetForm);
                            }}
                          />
                        </Box>
                        {(checkUserRole(loggedInUser!, UserRoles.Admin) ||
                          checkUserRole(loggedInUser!, UserRoles.Inventory)) && (
                          <Box sx={{}}>
                            <ActionButton
                              type="button"
                              text="Add New"
                              color="primary"
                              onClick={() => {
                                dispatch(resetDog());
                                navigate(`/admin/dog/0`);
                              }}
                            />
                          </Box>
                        )}
                      </Box>
                    </Box>
                  </Container>
                </Box>
                <Grid
                  container
                  spacing={1}
                  alignItems="center"
                  justifyContent={"center"}
                  sx={{ paddingBottom: 1, display: { sm: "flex", xs: showFilters ? "flex" : "none" } }}
                >
                  <Grid item md={4} xs={12}>
                    <Field
                      name="status"
                      label="Status"
                      component={Inputs.OptionDropdown}
                      searchFunction={getStatusOptions}
                      onSelected={handleStatusFilter}
                      multiple
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Field
                      name="dogSubStatus"
                      label="Sub Status"
                      component={Inputs.Dropdown}
                      options={subStatus?.results! || []}
                      multiple
                      onSelected={handleSubStatusFilter}
                      onChange={handleSubSearch}
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Field
                      name="foster"
                      label="Fosters"
                      component={Inputs.OptionDropdown}
                      searchFunction={getUserSearch}
                      onSelected={handleFosterFilter}
                      filterForm={{
                        pageNumber: 1,
                        pageSize: 10,
                        filters: ["role:3"],
                      }}
                      hideLargeResults
                      multiple
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <Field
                      name="intakeStart"
                      label="Intake Start"
                      component={Inputs.Datepicker}
                      handleChange={(value: string) => {
                        dispatch(setDogIntakeStart(new Date(value)));
                        if (date instanceof Date && !isNaN(new Date(value).valueOf()!)) {
                          dispatch(
                            getDogsSearch({
                              search: { ...dogFilter },
                              intakeStart: new Date(value),
                              intakeEnd: dogIntakeEnd!,
                              statusStart: dogStatusStart!,
                              statusEnd: dogStatusEnd!,
                            })
                          );
                        }
                      }}
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <Field
                      name="intakeEnd"
                      label="Intake End"
                      component={Inputs.Datepicker}
                      handleChange={(value: string) => {
                        dispatch(setDogIntakeEnd(new Date(value)));
                        if (date instanceof Date && !isNaN(new Date(value).valueOf()!)) {
                          dispatch(
                            getDogsSearch({
                              search: { ...dogFilter },
                              intakeStart: dogIntakeStart!,
                              intakeEnd: new Date(value)!,
                              statusStart: dogStatusStart!,
                              statusEnd: dogStatusEnd!,
                            })
                          );
                        }
                      }}
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <Field
                      name="statusStart"
                      label="Status Change Start"
                      component={Inputs.Datepicker}
                      handleChange={(value: string) => {
                        dispatch(setDogStatusStart(new Date(value)));
                        if (date instanceof Date && !isNaN(new Date(value).valueOf()!)) {
                          dispatch(
                            getDogsSearch({
                              search: { ...dogFilter },
                              intakeStart: dogIntakeStart!,
                              intakeEnd: dogIntakeEnd!,
                              statusStart: new Date(value),
                              statusEnd: dogStatusEnd!,
                            })
                          );
                        }
                      }}
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <Field
                      name="statusEnd"
                      label="Status Change End"
                      component={Inputs.Datepicker}
                      handleChange={(value: string) => {
                        dispatch(setDogStatusEnd(new Date(value)));
                        if (date instanceof Date && !isNaN(new Date(value).valueOf()!)) {
                          dispatch(
                            getDogsSearch({
                              search: { ...dogFilter },
                              intakeStart: dogIntakeStart!,
                              intakeEnd: dogIntakeEnd!,
                              statusStart: dogStatusStart!,
                              statusEnd: new Date(value)!,
                            })
                          );
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Search label="Search" handleChange={updateSearch} value={dogFilter.query} dontSearchOnLoad />
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </Container>
      {!!rows.length && status !== "loading" && checkUserRole(loggedInUser!, UserRoles.Admin) && (
        <Box sx={{ alignItems: "center", display: "flex", justifyContent: "center", marginBottom: "5px" }}>
          <Container>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: "flex-start",
                padding: 1,
                gap: 2,
              }}
            >
              <Box>
                <CheckboxButton
                  colorVariant="blue"
                  checked={selectAll}
                  onChange={handleSelectAll}
                  id="selectAll"
                  label="Select All"
                />
              </Box>
              <Box>
                <ActionButton
                  text="Print Selected"
                  onClick={printSelectedDocuments}
                  type="button"
                  disabled={
                    downloadStatus === "loading" || Object.values(checked).filter((value) => value === true).length < 2
                  }
                  color="primary"
                />
              </Box>
              <Box>{!!selecting && <CircularProgress size="15px" />}</Box>
            </Box>
          </Container>
        </Box>
      )}
      <Box margin={1}>
        {searchStatus === "loading" && !dogSearch ? (
          <Typography variant="h1" textAlign={"center"}>
            Loading, please wait...
          </Typography>
        ) : (
          <>
            <Typography variant="h2" sx={{ textAlign: "center", marginY: searchStatus === "loading" ? 0 : "29px" }}>
              {searchStatus === "loading" ? `Loading, please wait...` : <></>}
            </Typography>
            {rows.length ? (
              <StyledTable
                headers={headers}
                rows={rows}
                paging={true}
                page={dogFilter.pageNumber}
                rowsPerPage={dogFilter.pageSize}
                totalRows={dogSearch?.totalResults}
                changePage={changePage}
                sortOptions={handleSort}
                sizeVariant="large"
              />
            ) : (
              <Typography textAlign={"center"}>No dogs to display</Typography>
            )}
          </>
        )}
      </Box>
      <Dialog
        open={deleteModal}
        title={`Are you sure you would like to delete ${dog?.name}? This cannot be undone.`}
        warning
      >
        <Box>
          <DialogActions sx={{ display: "flex", justifyContent: "space-around" }}>
            <ActionButton type="button" text="Cancel" onClick={cancelDelete} />
            <ActionButton
              type="button"
              text={"Delete"}
              disabled={status === "loading" ? true : false}
              color="error"
              onClick={async () => {
                setDeleteModal(false);
                await handleDeleteDog(deleteId);
                setDeleteId(0);
                await dispatch(getDogsSearch({ search: { query: "", pageNumber: 1, pageSize: 10 } }));
              }}
            />
          </DialogActions>
        </Box>
      </Dialog>
    </Box>
  );
};

export default AdminDogTable;
