import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import theme from "../../theme";
import { Link, useNavigate } from "react-router-dom";
import { Instagram, Facebook } from "@mui/icons-material";
import ActionButton from "../base/ActionButton";
import Icon, { IconType } from "../base/Icon";

interface Props {}

const HelpUsView: React.FC<Props> = (props) => {
  const navigate = useNavigate();

  const volunteerCards = [
    {
      title: "Foster",
      text: "Provide love, care, and shelter for one (or more!) of our dogs until they find their permanent homes or for a defined period if you prefer a shorter commitment. You select the dogs you foster. Click here to find out more about fostering & to apply.",
      linkText: "Foster Page",
      link: "", //"/foster",
      disabled: true,
    },
    {
      title: "Volunteer",
      text: "Not able to bring a dog into your home to foster?  HAL has a variety of opportunities to help both remotely and in person aside from fostering! Click here to learn about some of these opportunities & to apply.",
      linkText: "Volunteer Page",
      link: "", // "/volunteer",
      disabled: true,
    },
    {
      title: "Donate",
      text: "Supplies and monetary donations are always appreciated. Check out this page for more information on ways to donate.",
      linkText: "Donate Page",
      link: "/donate",
    },
    {
      title: "Host an Event or Fundraiser",
      text: "Consider running a food/ supplies drive, organizing a raffle, hosting a bake sale…the options are endless. Contact us so we can help you get the resources you need to create an amazing event. Click here to email us at info@homeatlastdogrescue.com",
      email: "Compose Email",
    },
    {
      title: "Network & Educate",
      text: "Share posts on social media. When friends and family are looking to add a dog to their family, send them to us. Visit our social media now for HAL news.",
      socials: true,
    },
    {
      title: "Adopt",
      text: "Instead of purchasing through a breeder or pet store, adopt one of our amazing dogs. Your life will never be the same! We rescue dogs every week. You can check out who is available for adoption now by clicking here.",
      linkText: "Adoptable Dogs",
      link: "/dogs",
    },
  ];

  return (
    <Grid container direction={"row"} pb={5}>
      <Grid item xs={12}>
        <Box sx={{ marginTop: 5 }} />
      </Grid>

      {/* Left Side */}
      <Grid item md={7} sm={12} sx={{ paddingX: { lg: 10, md: 5, xs: 2 } }}>
        <Grid container direction={"column"} spacing={2}>
          <Grid item sx={{ mb: 3, display: { md: "none", xs: "flex" } }} alignSelf={"center"}>
            <Box
              component="img"
              alt={`person holding up a dog`}
              src="/static/images/volunteer-image.jpeg"
              sx={{
                objectFit: "cover",
                height: { sm: "300px", xs: "200px" },
                borderRadius: "25px",
              }}
            />
          </Grid>
          <Grid item>
            <Typography variant="h2" sx={{ mb: 3 }}>
              We can't do it{" "}
              <Box display={"inline"} fontWeight={"bold"} component={"span"}>
                without you.
              </Box>
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              We are thrilled you want to help Home At Last.
            </Typography>

            <Typography variant="body1" sx={{ mb: 2 }}>
              Our fosters/volunteers and supporters are an essential part of our rescue. We can't wait to introduce you
              to our organization and the many ways you can help us continue our life-saving mission.
            </Typography>

            <Typography variant="body1" sx={{ mb: 2 }}>
              Please take a few minutes to peruse our website to learn more about HAL. Read the stories of the dogs who
              are waiting for their forever family. Check out our scheduled events.
            </Typography>
            {/* add back when volunteer applications are added */}
            {/* <Typography variant="body1" sx={{ mb: 2 }}>
              When you're ready, please fill out our VOLUNTEER APPLICATION!
            </Typography> */}
          </Grid>
        </Grid>
      </Grid>
      {/* Right Side */}
      <Grid item md={5} sm={12} sx={{ paddingX: { lg: 10, md: 7, xs: 5 }, display: { md: "flex", xs: "none" } }}>
        <Grid container direction={"column"}>
          <Grid item sx={{ mb: 5 }}>
            <Box
              component="img"
              alt={`person holding up a dog`}
              src="/static/images/volunteer-image.jpeg"
              sx={{
                objectFit: "cover",
                height: "300px",
                width: "100%",
                borderRadius: "25px",
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ paddingX: { lg: 10, xs: 5 } }}>
        {/* add back when volunteer applications are added */}
        {/* <Box sx={{ marginBottom: 5, textAlign: "center" }} gap={5} columnGap={5} rowGap={5}>
          <Typography variant="h2">Join our pack!</Typography>
          <Typography>Pick from multiple volunteer opportunities below:</Typography>
        </Box> */}

        <Grid container spacing={4} mt={1}>
          {volunteerCards.map((card, index) => {
            return (
              <Grid item lg={4} md={6} xs={12} key={index}>
                <Grid
                  container
                  direction={"column"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  sx={{
                    backgroundColor: theme.palette.background.paper,
                    height: { lg: 400, md: 325, xs: 400 },
                    paddingX: { md: 5, xs: 1.5 },
                    paddingBottom: 3,
                    marginBottom: 4,
                    borderRadius: 5,
                    cursor: card.link ? "pointer" : "default",
                  }}
                  onClick={
                    card.link
                      ? () => {
                          navigate(card.link);
                        }
                      : undefined
                  }
                >
                  <Grid
                    item
                    sx={{
                      backgroundColor: theme.palette.primary.main,
                      padding: 1,
                      borderRadius: 2,
                      marginTop: "-20px",
                      zIndex: 100,
                      alignSelf: "center",
                    }}
                  >
                    <Typography color={theme.palette.primary.contrastText} textAlign={"center"}>
                      {card.title}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography textAlign={"center"}>{card.text}</Typography>
                  </Grid>
                  <Grid item alignSelf={"stretch"}>
                    {card.title === "Foster" ? (
                      <Typography textAlign="center" variant="h5">
                        For information on fostering opportunities please email us at info@homeatlastdogrescue.com
                      </Typography>
                    ) : !!card.disabled ? (
                      <Typography textAlign="center" variant="h5" color="error">
                        We are not currently accepting new submissions. Please check back later!
                      </Typography>
                    ) : card.email ? (
                      <a
                        href="mailto:info@homeatlastdogrescue.com?subject=I Want to Plan an Event"
                        style={{ color: theme.palette.primary.main, textDecoration: "none" }}
                      >
                        <ActionButton text={card.email} onClick={() => {}} type="button" fullWidth color="error" />
                      </a>
                    ) : card.linkText ? (
                      <ActionButton text={card.linkText} onClick={() => {}} type="button" fullWidth color="error" />
                    ) : card.socials ? (
                      <Grid container direction={"row"} justifyContent={"space-around"}>
                        <Link
                          to="https://www.tiktok.com/@homeatlastdogrescue?_t=8dbZZxiwFsE&_r=1"
                          target={"_blank"}
                          style={{ marginTop: "12px" }}
                        >
                          <Icon type={IconType.TikTok} color={theme.palette.error.main} size="35px" />
                        </Link>
                        <Link
                          to="https://www.instagram.com/homeatlastdogrescue/?hl=en"
                          target={"_blank"}
                          style={{ fontSize: "50px" }}
                        >
                          <Instagram color="error" fontSize="inherit" />
                        </Link>
                        <Link
                          to="https://www.facebook.com/HomeAtLastDogRescuePA"
                          target={"_blank"}
                          style={{ fontSize: "50px" }}
                        >
                          <Facebook color="error" fontSize="inherit" />
                        </Link>
                      </Grid>
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
          {/* add back when volunteer applications are added */}
          {/* <Grid item md={8} xs={12}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Box
                sx={{
                  backgroundColor: theme.palette.primary.main,
                  padding: 1,
                  borderRadius: 2,
                  marginBottom: "-25px",
                  zIndex: 100,
                  alignSelf: "center",
                }}
              >
                <Typography color={theme.palette.primary.contrastText}>Join Our Pack</Typography>
              </Box>
              <Box
                sx={{
                  backgroundColor: theme.palette.background.paper,
                  height: 200,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  borderRadius: 5,
                  justifyContent: "center",
                }}
              >
                <Button color="primary" variant="contained" onClick={() => {}}>
                  Sign Up Today
                </Button>
              </Box>
            </Box>
          </Grid> */}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default HelpUsView;
