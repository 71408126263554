import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import theme from "../../theme";
import DonationBox from "./DonationBox";
import DonorWall from "./DonorWall";

interface Props {}

const DonateView: React.FC<Props> = (props) => {
  const moniesList = [
    "$10 — Deworming, fecal test, leash, collar",
    "$25 — Vaccination, flea & tick preventative, heartworm test",
    "$50 — Microchip, routine vet visit",
    "$100 — Bloodwork, x-rays",
    "$250 — Spay and neuter surgery, dental cleaning",
    "$500 — Cardiologist work up and exam",
    "$1000+ — Heartworm treatment, major surgery like ACL, eye removal",
  ];

  const suppliesList = [
    "Dog Food and Treats",
    "Pill pockets, jars of baby food (any puree meat)",
    "Dog toys (eg Kongs, toys that squeak) and chews (eg Benebones, Nylabones, elk antlers)",
    "Flea/Tick Preventative (eg Frontline, Advantix)",
    "Heartworm Preventative (we prefer Heartgard)",
    "Collars, Non-retractable leashes, Harnesses",
    "Dog Beds",
    "Wire crates",
    "Foldable exercise pens",
    "Nature's Miracle Wipes, Fragrance-Free wet wipes",
    "Cleaning supplies: Paper towels, disposable plastic trash bags, Spray w/ bleach",
    "Puppy pads and newspaper",
    "Gift cards where supplies can be purchased (eg Chewy, Amazon, any Pet Store)",
  ];

  return (
    <Grid container direction={"row"} sx={{ paddingX: { xl: 25, lg: 10, md: 5, xs: 0 } }}>
      <Grid item xs={12}>
        <Box sx={{ marginTop: 1, marginBottom: { xs: 0, md: 5 } }}>
          <Typography textAlign={"center"} variant="h1">
            Donate
          </Typography>
        </Box>
      </Grid>
      <Grid container direction={"row"} marginTop={2}>
        <Grid item md={8} xs={12} paddingX={1}>
          <Grid
            container
            direction={"row"}
            marginTop={"15px"}
            display={"flex"}
            justifyContent={"center"}
            marginBottom={2}
            paddingBottom={{ xs: 5, sm: 4, md: 3 }}
            gap={2}
            sx={{ border: `4px solid ${theme.palette.primary.main}` }}
          >
            <Grid item xs={12} display="flex" justifyContent="center" sx={{ height: "fit-content" }}>
              <Box
                component="img"
                alt={`how your donations support our dogs`}
                src="/static/images/donation-dog.jpeg"
                sx={{
                  marginTop: { xs: "0px", sm: "-40px", md: "-33px" },
                  objectFit: "contain",
                  height: { xs: "350px", sm: "400px", md: "650px" },
                  width: "100%",
                }}
              />
            </Grid>
            <Grid item xs={12} alignContent={"center"}>
              <Grid container display="flex" textAlign="center" direction={"column"} rowSpacing={2}>
                <Grid item>
                  <Typography textAlign="center" fontStyle={"italic"} variant={"h2"} marginBottom={3}>
                    No donation is too small.
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography textAlign="center" variant="h3">
                    Checks can be mailed to:
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography textAlign="center" variant="h2">
                    Home At Last Dog Rescue
                  </Typography>
                  <Typography textAlign="center" variant="h2">
                    P.O. Box 1341
                  </Typography>
                  <Typography textAlign="center" variant="h2">
                    North Wales, PA 19454
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={4} xs={12} paddingX={1} display={{ xs: "none", md: "flex" }} justifyContent="center">
          <DonationBox />
        </Grid>
      </Grid>
      <Grid container height={"900px"} direction={"row"} marginTop={2} display={{ xs: "flex", md: "none" }}>
        <Grid item xs={12} paddingX={1} display="flex" justifyContent="center">
          <DonationBox />
        </Grid>
      </Grid>
      {/* <Grid container direction={"row"}>
        <Grid item md={8} xs={12} paddingX={1}>
          <Grid
            container
            direction={"row"}
            marginTop={"2px"}
            display={"flex"}
            justifyContent={"center"}
            marginBottom={2}
            paddingBottom={{ md: 0, xs: 5 }}
            sx={{ border: `4px solid ${theme.palette.primary.main}` }}
          >
            <Grid item md={6} xs={12} display="flex" justifyContent="center" sx={{ height: "fit-content" }}>
              <Box
                component="img"
                alt={`how your donations support our dogs`}
                src="/static/images/donation-dog.jpeg"
                sx={{
                  marginTop: { xl: "-48px", lg: "-35px", md: "-33px" },
                  objectFit: "contain",
                  height: { xl: "415px", lg: "322px", md: "305px", sm: "600px" },
                  width: "100%",
                }}
              />
            </Grid>
            <Grid item md={6} xs={12} alignContent={"center"}>
              <Grid container display="flex" textAlign="center" direction={"column"} rowSpacing={2}>
                <Grid item>
                  <Typography textAlign="center" fontStyle={"italic"} variant={"h2"} marginBottom={3}>
                    No donation is too small.
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography textAlign="center" variant="h3">
                    Checks can be mailed to:
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography textAlign="center" variant="h2">
                    Home At Last Dog Rescue
                  </Typography>
                  <Typography textAlign="center" variant="h2">
                    P.O. Box 1341
                  </Typography>
                  <Typography textAlign="center" variant="h2">
                    North Wales, PA 19454
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={4} xs={12} sx={{ display: { xs: "flex", md: "none" } }}>
            <Grid container direction={"column"} sx={{ paddingX: 0 }}>
              <Grid item xs={12} display="flex" justifyContent="center">
                <DonationBox />
              </Grid>
            </Grid>
          </Grid>
          <Grid container direction={"row"}>
            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="center"
              sx={{ height: { xl: "365px", lg: "442px", md: "458px", xs: "1000px" }, marginBottom: { md: 0, xs: 5 } }}
            >
              <DonorWall />
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={4} xs={12} sx={{ display: { xs: "none", md: "flex" } }}>
          <Grid container direction={"row"} sx={{ paddingX: 2 }}>
            <Grid item md={12} sm={6} xs={12} display="flex" justifyContent="center">
              <DonationBox />
            </Grid>
          </Grid>
        </Grid>
      </Grid> */}
      <Grid container direction={"row"}>
        <Grid item xs={12} mt={{ xs: 0, sm: -12, md: 5 }} mb={5}>
          <Grid container direction={"column"} rowSpacing={2}>
            <Grid item>
              <Typography textAlign={{ xs: "center", md: "left" }} variant="h1">
                WE ALWAYS NEED SUPPLIES
              </Typography>
            </Grid>
            <Grid item>
              <Typography textAlign={{ xs: "center", md: "left" }} variant="body1">
                Supplies can be dropped off at any of our events (check our home page for dates, times, and locations).
              </Typography>
            </Grid>
            <Grid item>
              <Typography textAlign={{ xs: "center", md: "left" }} variant="body1" fontStyle={"italic"}>
                Please don't drop off or have items delivered to our headquarters during non-event hours; we don't staff
                or visit the building daily .
              </Typography>
            </Grid>
            <Grid item>
              <a
                href={`mailto:info@homeatlastdogrescue.com?subject=Supplies donation`}
                target="_blank"
                style={{ color: theme.palette.primary.main }}
                rel="noreferrer"
              >
                <Typography textAlign={{ xs: "center", md: "left" }} variant="body1">
                  Contact us to make other arrangements
                </Typography>
              </a>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container direction={"row"} marginTop={7}>
        <Grid item xs={12} mb={5}>
          <Grid container direction={"column"}>
            <Grid item>
              <Typography textAlign={{ xs: "center", md: "left" }} variant="h2" sx={{ mb: 3 }}>
                Our needs include:
              </Typography>
            </Grid>
            <Grid item>
              <ul>
                {suppliesList.map((li, index) => (
                  <li key={index}>
                    <Grid container direction={"row"} justifyContent={"flex-start"} alignItems={"center"} my={1}>
                      <Grid item>
                        <Typography>{li}</Typography>
                      </Grid>
                    </Grid>
                  </li>
                ))}
              </ul>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} mb={5}>
          <Grid container direction={"column"} rowSpacing={2}>
            <Grid item>
              <Typography textAlign={"center"} variant="h1">
                Our Wishlists
              </Typography>
            </Grid>
            <Grid item>
              <Typography textAlign={"center"} variant="h3">
                Order from our Amazon or Chewy Wish list and your purchase is shipped directly to us.
              </Typography>
            </Grid>
            <Grid item>
              <Typography textAlign={"center"} variant="h3">
                Easy for you, greatly appreciated by us!!!
              </Typography>
            </Grid>
            <Grid container direction={"row"} justifyContent={"space-evenly"} my={5}>
              <Grid item>
                <a
                  href="https://www.amazon.com/hz/wishlist/ls/1EXEHR1MZIJ6V?ref_=wl_share"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Box
                    component="img"
                    alt={`Amazon Wishlist`}
                    src={"/static/images/AmazonWishlist.jpg"}
                    sx={{
                      objectFit: "cover",
                      height: "200px",
                      width: "250px",
                    }}
                  />
                </a>
              </Grid>
              <Grid item>
                <a href="https://www.chewy.com/g/home-at-last-dog-rescue_b66323136" target="_blank" rel="noreferrer">
                  <Box
                    component="img"
                    alt={`Chewy Wishlist`}
                    src={"/static/images/ChewyWishlist.jpg"}
                    sx={{
                      objectFit: "cover",
                      height: "200px",
                      width: "250px",
                    }}
                  />
                </a>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DonateView;
