import React, { useState } from "react";
import { useDispatch, useSelector } from "../../store";
import { Box, Typography, Divider, Button, MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faUser } from "@fortawesome/free-solid-svg-icons";
import { logout, setAuthModal, setAuthRoute } from "../../slices/auth";
import { resetApplication } from "../../slices/applications";
import { resetUserValues } from "../../slices/users";
import adminReroute from "../../helpers/adminReroute";

interface Props {
  admin?: boolean;
}

const PopoverMenu: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loggedInUser } = useSelector((store) => store.auth);

  const [logoutDisable, setLogoutDisable] = useState<boolean>(false);

  const handleLogout = async () => {
    setLogoutDisable(true);
    if (!!loggedInUser) {
      dispatch(logout());
      dispatch(resetApplication());
      dispatch(resetUserValues());
      navigate("/home");
      dispatch(setAuthModal(true));
      dispatch(setAuthRoute("login"));
      setLogoutDisable(false);
    }
  };

  return (
    <Box sx={{ width: "200px", zIndex: 50000 }}>
      <Box sx={{ py: "10px" }}>
        <React.Fragment>
          <MenuItem
            onClick={() => navigate("/user-profile", { state: { id: loggedInUser?.id } })}
            sx={{ display: "flex", gap: "20px" }}
          >
            <FontAwesomeIcon icon={faUser} size="sm" />
            <Typography color="textPrimary" variant="subtitle2">
              My Profile
            </Typography>
          </MenuItem>
          {loggedInUser?.roles?.length! > 0 && !props.admin && (
            <MenuItem onClick={() => navigate(adminReroute(loggedInUser!))} sx={{ display: "flex", gap: "20px" }}>
              <FontAwesomeIcon icon={faCog} size="sm" />
              <Typography color="textPrimary" variant="subtitle2">
                Admin Site
              </Typography>
            </MenuItem>
          )}
          {!!props.admin && (
            <MenuItem onClick={() => navigate("/")} sx={{ display: "flex", gap: "20px" }}>
              <FontAwesomeIcon icon={faCog} size="sm" />
              <Typography color="textPrimary" variant="subtitle2">
                Marketing Site
              </Typography>
            </MenuItem>
          )}
        </React.Fragment>
      </Box>
      <Divider />
      <Box sx={{ display: "flex", justifyContent: "center", marginY: "5px" }}>
        <Typography color="textPrimary" variant="subtitle2">
          1.12.01.2107
        </Typography>
      </Box>
      <Divider />
      <Box sx={{ p: "10px" }}>
        <Button color="primary" fullWidth variant="contained" onClick={handleLogout} disabled={logoutDisable}>
          Logout
        </Button>
      </Box>
    </Box>
  );
};

export default PopoverMenu;
