import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { Field, Form, Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { Inputs } from "../../forms";
import { getReferralSources } from "../../../slices/options";
import { useDispatch, useSelector } from "../../../store";
import OtherInfo from "../../../types/application/otherInfo";
import { saveOtherStep } from "../../../slices/applications";
import { handleErrorToastState, setErrorMessage } from "../../../slices/toast";

interface Props {
  formikRef?: React.RefObject<FormikProps<any>>;
}

const OtherInfoStep: React.FC<Props> = (props) => {
  const dispatch = useDispatch();

  const initialState: OtherInfo = useSelector((store) => ({
    halReferral: store.applications.myApplication?.halReferral,
    otherInfo: store.applications.myApplication?.otherInfo,
    agreedToTerms: false,
  }));

  const handleSubmit = (values: any) => {
    dispatch(saveOtherStep(values));
  };

  const validationSchema = Yup.object().shape({
    halReferral: Yup.object().typeError("Must select an option").nullable(),
    otherInfo: Yup.string().nullable(),
    agreedToTerms: Yup.boolean().typeError("Must be true").oneOf([true], "Agreeing to this statement is required"),
  });

  return (
    <Formik
      initialValues={initialState}
      validationSchema={validationSchema}
      innerRef={props.formikRef}
      onSubmit={handleSubmit}
      validateOnChange={true}
      validateOnMount
    >
      {({ values }) => (
        <Form noValidate>
          <Grid container sx={{ paddingTop: "5px" }}>
            <Grid item xs={12} md={12}>
              <Field
                component={Inputs.OptionDropdown}
                searchFunction={getReferralSources}
                name="halReferral"
                label={`How did you hear about HAL?`}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Field
                component={Inputs.TextArea}
                name="otherInfo"
                label={`Is there any other info you'd like to share with us?`}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Box sx={{ marginX: 2, marginBottom: 2 }}>
                <Typography variant="body2">
                  Every dog placed through Home At Last Dog Rescue (HAL) will have a health screening, which includes
                  administering core vaccinations when needed, a heartworm test, and will be microchipped. Also, every
                  dog will be sterilized prior to placement unless a veterinarian has diagnosed a medical condition that
                  would prevent this surgery from being done before adoption. Additionally, personality and temperament
                  assessments are done by the foster.
                </Typography>
              </Box>
              <Box sx={{ margin: 2 }}>
                <Typography variant="body2">
                  Information provided on this application plus reference checks and a home visit are used to determine
                  if the dog is a good match. Home At Last reserves the right to select the best placement for each HAL
                  dog. This application is not a guarantee of any dog’s placement in my home.
                </Typography>
              </Box>
              <Box sx={{ marginX: 2, marginTop: 2 }}>
                <Typography variant="body2">
                  All information on this application will be kept confidential and will remain on file until I notify
                  Home At Last that I am no longer interested in adopting a HAL dog.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={12} pb={1}>
              <Box sx={{ margin: 2 }}>
                <Field
                  name="agreedToTerms"
                  label={
                    <>
                      I have read and understand the above disclaimer, and, to the best of my knowledge, all information
                      included in the application is true and accurate. <strong>Click paw print to confirm.</strong>
                    </>
                  }
                  component={Inputs.Checkbox}
                  colorVariant="blue"
                  fontVariant="body2"
                  validate={(value: boolean) => {
                    if (value === false) {
                      // dispatch(handleErrorToastState(true));
                      // dispatch(setErrorMessage("You must read and agree to the terms before submitting"));
                    }
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default OtherInfoStep;
